<template>
  <monitoring-items
    :activeModules="activeModules"
    :mapItems="mapEuropeanUnionDirectivesItems"
    :api="{
      moduleId: 'pk',
      modulesWithName: 'custom_query_european_union_acts_with_names',
      modules: 'module_european_union_directives_header',
      favoriteHated: 'favorite_and_hated_european_union_directives_header',
      favoriteHatedResponse: 'favorite_and_hateful_european_union_directives_list',
      bookmarks: 'bookmarks/fetchEuropeanUnionDirectives',
    }"
    #default="{items}"
  >
    <european-union-directives-list :items="items" />
  </monitoring-items>
</template>

<script>
import MonitoringItems from '../components/MonitoringItems';
import EuropeanUnionDirectivesList from '../../EuropeanUnionDirectives/EuropeanUnionDirectivesList';
import { mapEuropeanUnionDirectivesItems } from '../../EuropeanUnionDirectives/mapEuropeanUnionDirectivesItems';

export default {
  props: {
    activeModules: Array,
  },
  components: {
    MonitoringItems,
    EuropeanUnionDirectivesList,
  },
  metaInfo() {
    return {
      title: 'Monitoring dyrektyw UE',
    };
  },
  data() {
    return {
      mapEuropeanUnionDirectivesItems: mapEuropeanUnionDirectivesItems,
    };
  },
};
</script>
